/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1et7zay --parallax pb--50 pt--50" anim={null} name={"intro"} animS={null} style={{"minHeight":"100vh"}} layout={"l1"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/161/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/161/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/161/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/161/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/161/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/161/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/161/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/161/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pt--40 flex--center" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s7" anim={"2"} animS={"7"} style={{"maxWidth":800}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(255, 255, 255);\">Merry Christmas and happy New Year</span>"}>
              </Subtitle>

              <Title className="title-box ff--1 fs--220 mt--20" style={{"maxWidth":1172}} content={"<span style=\"color: rgb(255, 255, 255);\">2023</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center mt--20" style={{"maxWidth":""}} content={"&nbsp;from Jiri Stloukal"}>
              </Subtitle>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/161/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":550}} srcSet={"https://cdn.swbpg.com/t/i/template/161/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/161/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/161/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/161/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/161/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/3b208b5895ab48968e6cd15fc3f09918.svg"} sizes={"100vw"} style={{"maxWidth":312}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}